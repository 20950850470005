import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageLoader from 'components/molecules/PageLoader/PageLoader';
import lng from 'utils/languageRoute';

const Home = lazy(() => import('views/Home/Home'));
const Login = lazy(() => import('views/Login/Login'));
const Register = lazy(() => import('views/Register/Register'));
const TokenEmail = lazy(() => import('views/TokenEmail/TokenEmail'));
const Privacy = lazy(() => import('views/Privacy/Privacy'));
const Tos = lazy(() => import('views/Tos/Tos'));
const ResetPassword = lazy(() => import('views/ResetPassword/ResetPassword'));
const NotFound = lazy(() => import('views/NotFound/NotFound'));
const Routes = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      <Route exact path={`${lng}/`} component={Home} />
      <Route exact path={`${lng}/register`} component={Register} />
      <Route exact path={`${lng}/login`} component={Login} />
      <Route exact path={`${lng}/privacy`} component={Privacy} />
      <Route exact path={`${lng}/tos`} component={Tos} />
      <Route exact path={`${lng}/tokenEmail/:token`} component={TokenEmail} />
      <Route exact path={`${lng}/resetPassword/:token`} component={ResetPassword} />
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export default Routes;
