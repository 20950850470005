// @ts-nocheck
import styled from 'styled-components';
import { typography, spacing } from '@material-ui/system';
import { Typography } from '@material-ui/core';

const Text = styled(Typography)`
  ${typography}
  ${spacing}
`;

export default Text;
