import axios from 'axios';
import { isProd } from 'utils/nodeEnv';

let source = axios.CancelToken.source();

const getInitialData = async () => {
  const response = await axios.get('https://fastcast4u.com/api/general/initialResponse.php', {
    cancelToken: source.token,
  });
  return response.data;
};

const getFaq = async () => {
  const response = await axios.get(`https://freeshoutcast.com/api/faq`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });
  return response.data;
};

const getItemsLeft = async promocode => {
  const response = await axios.get(`https://fastcast4u.com/api/getpricing/checkPromotion.php`, {
    params: { promocode },
    cancelToken: source.token,
  });

  return response.data;
};

const cancel = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

export default {
  getInitialData,
  getItemsLeft,
  getFaq,
  cancel,
};
