import axios from 'axios';

const baseUrl = 'https://strapi.fastcast4u.com/';
let source = axios.CancelToken.source();

const getTopBarData = async () => {
  const response = await axios.get(`${baseUrl}general`, {
    cancelToken: source.token,
  });
  return {
    from: new Date(response.data.from),
    to: new Date(response.data.to),
    bar: response.data.bar,
  };
};

const getLoginData = async () => {
  const response = await axios.get(`${baseUrl}login`, {
    cancelToken: source.token,
  });
  return {
    heading: response.data.Heading,
    image: response.data.image?.url,
    button: response.data.button,
  };
};

const getNotificationData = async () => {
  const response = await axios.get(`${baseUrl}promo-notification`, {
    cancelToken: source.token,
  });
  return {
    from: new Date(response.data.from),
    to: new Date(response.data.to),
    active: response.data.active,
    content: response.data.content,
    date: response.data.date,
    image: response.data.image?.url,
    mobile: response.data.mobile,
    link: response.data.link,
  };
};

const cancel = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

export default {
  getTopBarData,
  getLoginData,
  getNotificationData,
  cancel,
};
