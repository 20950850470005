// @ts-nocheck
import React from 'react';
import { Container } from '@material-ui/core';
import FooterContainer from 'components/atoms/FooterContaier/FooterContainer';
import FooterLinks from 'components/molecules/FooterLinks/FooterLinks';
import FooterThemeProvider from 'theme/FooterThemeProvider';

const Footer = () => {
  return (
    <FooterThemeProvider>
      <FooterContainer component="footer" maxWidth={false} p={0}>
        <Container maxWidth="xl">
          <FooterLinks />
        </Container>
      </FooterContainer>
    </FooterThemeProvider>
  );
};

export default Footer;
