import produce from 'immer';

const initialState = {
  topBar: {
    bar: {},
  },

  login: {
    heading: '',
    button: {},
  },

  notification: {
    active: false,
    content: '',
    date: '',
    image: '',
    mobile: '',
    link: '',
  },
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOP_BAR_DATA':
      return produce(state, draftState => {
        draftState.topBar = { ...state.topBar, ...action.payload };
      });

    case 'SET_LOGIN_DATA':
      return produce(state, draftState => {
        draftState.login = action.payload;
      });

    case 'SET_NOTIFICATION_DATA':
      return produce(state, draftState => {
        draftState.notification = action.payload;
      });
    default:
      return state;
  }
};

export default generalReducer;
